import { ref, onMounted, onUnmounted } from 'vue'
import { devices } from '~/constants/general'

type Device = (typeof devices)[keyof typeof devices]

export function useScreenSize() {
  const activeDevice = ref<Device>(devices.DESKTOP)

  const mediaQuerySmall = window?.matchMedia('(max-width: 768px)')
  const mediaQueryMedium = window?.matchMedia(
    '(min-width: 769px) and (max-width: 1024px)',
  )
  const mediaQueryNormal = window?.matchMedia(
    '(min-width: 1025px) and (max-width: 1280px)',
  )
  const mediaQueryLarge = window?.matchMedia(
    '(min-width: 1281px) and (max-width: 1535px)',
  )
  const mediaQueryExtraLarge = window?.matchMedia('(min-width: 1536px)')

  const getBreakpoints = () => {
    if (mediaQueryExtraLarge?.matches) {
      activeDevice.value = devices.DESKTOP_BIG
    }
    if (mediaQueryLarge?.matches) {
      activeDevice.value = devices.DESKTOP
    }
    if (mediaQueryNormal?.matches) {
      activeDevice.value = devices.DESKTOP_SMALL
    }
    if (mediaQueryMedium?.matches) {
      activeDevice.value = devices.TABLET
    }
    if (mediaQuerySmall?.matches) {
      activeDevice.value = devices.MOBILE
    }
  }

  const isBigDesktop = computed(
    () => activeDevice.value === devices.DESKTOP_BIG,
  )
  const isMobile = computed(() => activeDevice.value === devices.MOBILE)
  const isTablet = computed(() => activeDevice.value === devices.TABLET)
  const isNotDesktop = computed(() => isMobile.value || isTablet.value)

  onMounted(async () => {
    await nextTick(() => {
      getBreakpoints()
      mediaQuerySmall.addEventListener('change', getBreakpoints)
      mediaQueryMedium.addEventListener('change', getBreakpoints)
      mediaQueryNormal.addEventListener('change', getBreakpoints)
      mediaQueryLarge.addEventListener('change', getBreakpoints)
      mediaQueryExtraLarge.addEventListener('change', getBreakpoints)
    })
  })

  onUnmounted(() => {
    mediaQuerySmall.removeEventListener('change', getBreakpoints)
    mediaQueryMedium.removeEventListener('change', getBreakpoints)
    mediaQueryNormal.removeEventListener('change', getBreakpoints)
    mediaQueryLarge.removeEventListener('change', getBreakpoints)
    mediaQueryExtraLarge.removeEventListener('change', getBreakpoints)
  })

  return {
    activeDevice,
    isBigDesktop,
    isMobile,
    isTablet,
    isNotDesktop,
  }
}
